@include media(lg) {
    .o-blog-post-intro {
        margin-top: $space * 20;
        margin-bottom: $space * 24;

        &__above-divider {
            margin-bottom: $space * 14;
        }

        &__pre-header {
            margin-bottom: $space * 2;
        }

        &__headline {
            margin-bottom: $space * 6;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1068";