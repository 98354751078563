.no-orders-found {
    margin-top: $space * 4;

    .info-box__image {
        display: none;
    }

    &__content {
        text-align: left;
        padding: $space * 5 $space * 3;
    }

    &__title {
        padding-bottom: $space * 5;
    }

    &__additional-text {
        margin-left: $space * 6;

        li {
            padding-left: $space * 2;
        }

        li::marker {
            position: relative;
            content: '■';
            font-family: $font-family--font-awesome;
            left: 0;
            color: $blue;
            font-size: $font-size--medium-plus;
        }

        li:first-child {
            margin-bottom: $space;
        }
    }

    &__divider {
        margin: $space * 10 0 $space * 12;
    }
}

;@import "sass-embedded-legacy-load-done:724";