@include media(lg) {
    .o-product-buy-box {
        width: percentage(calc(1 / 3));
        margin-top: $space * 2;
        padding-left: $space;

        &__content {
            margin-bottom: $space * 3;
            margin-left: inherit;
            margin-right: inherit;

            @include border('top');
            @include border('left');
            @include border('right');

            .quantity-field__container {
                position: relative;

                .tooltip {
                    min-width: 180px;
                    left: -30px;
                }
            }
        }

        &__sticky-abtest29887 {
            grid-template-columns: 70px 36.44fr 150px 36.44fr;

            .button--message__hover {
                display: inline-block !important;
            }

            .button--message__text,
            .button__text {
                display: block;
            }
        }
        &__quantity-field-abtest29887 {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1265";