.o-category-tree {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    &__wrapper {
        width: 100%;
        margin: 0 0 ($space * 14);
    }

    &__header {
        margin-bottom: $space * 4;
    }

    &__block {
        padding: 0;
        overflow: hidden;
        width: 100%;

        &.o-category-tree__block--not-collapsible {
            margin-bottom: 0;
            border-bottom: none;

            .o-category-tree__block-item {
                margin-left: 0;
                margin-bottom: $space * 6;
            }

            .a-list {
                margin-top: 0;
            }
        }

        .a-list {
            margin-top: $space * 2;
        }

        @include border($where: 'bottom', $width: 1px, $color: $grey--medium);

        &-header {
            margin-top: $space * 4;
            margin-bottom: $space * 6;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-item {
            margin-bottom: $space * 5;
            margin-left: $space * 5;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .m-title-header {
            margin: ($space * 4) 0;

            &__container {
                padding: 0;
            }

            &__button--right {
                margin: ($space * 4) 0;
            }

            &__button--right .fa {
                font-size: $font-size--large;
            }
        }

        .t-panel-v2__body {
            padding: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1339";