@include media(xxl) {
    .o-logo-pool {
        &__container {
            column-gap: $space * 2;

            &__item {
                padding: $space * 3;
            }
        }
        &__logo {
            height: 88px;
            width: 175px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1413";