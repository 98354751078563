.o-product-substitute {
    @include border('bottom');

    box-shadow: $panel-shadow;
    border-radius: $border-radius;
    padding: $space * 3;

    &__content {
        display: flex;
        color: $grey--darker;
        font-weight: $font-weight--bold;

        &:hover {
            text-decoration: none;
        }
    }

    &__image {
        height: 80px;
    }

    &__text {
        margin-left: $space;
    }

    &__name {
        max-height: 36px;
        overflow: hidden;
        margin-top: $space;
        margin-bottom: $space;

        &:hover {
            text-decoration: underline;
        }
    }
}
;@import "sass-embedded-legacy-load-done:1297";