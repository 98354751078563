@mixin switch-dot($on: false) {
    display: inline-block;
    content: '';
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 20px;
    box-shadow: $shadow7;

    @if $on {
        margin-left: 8px;
        margin-right: 2px;
    } @else {
        margin-left: 2px;
        margin-right: 8px;
    }
}

@mixin switch-dot--large($on: false) {
    display: inline-block;
    content: '';
    width: 30px;
    height: 30px;
    background: white;
    border-radius: 20px;
    box-shadow: $shadow7;

    @if $on {
        margin-left: 8px;
        margin-right: 2px;
    } @else {
        margin-left: 2px;
        margin-right: 8px;
    }
}

.a-switch-with-text {
    display: flex;
    align-items: center;
    width: 103px;
    height: 24px;
    cursor: pointer;
    border-radius: 12px;
    background: $grey--medium;
    color: $grey--darker;
    font-size: $font-size--small;

    &::before {
        @include switch-dot($on: false);
    }

    &--selected {
        &:hover {
            background: $blue;
        }

        &::before {
            display: none;
        }

        &::after {
            @include switch-dot($on: true);
        }

        color: white;
        background: $theme-color;
        font-weight: $font-weight--bold;
        justify-content: flex-end;
    }
}

.a-switch-with-text--large {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 18px;
    background: $grey--medium;
    color: $grey--darker;
    width: 133px;
    height: 36px;
    font-size: $font-size--regular-plus;
    margin: 0 auto;

    &::before {
        @include switch-dot--large($on: false);
    }

    &-selected {
        &:hover {
            background: $blue;
        }

        &::before {
            display: none;
        }

        &::after {
            @include switch-dot--large($on: true);
        }

        color: white;
        background: $theme-color;
        font-weight: $font-weight--bold;
        justify-content: flex-end;
    }
}

.a-switch__label {
    width: 100%;
    margin-right: $space * 2;
    margin-bottom: $space * 2;
    text-align: center;
    font-size: $font-size--medium;
    color: $grey--dark;

    @include media(md) {
        width: calc(100% - 133px);
        max-width: 170px;
        margin-bottom: 0;
        text-align: left;
    }
}

.m-customer-type-switch--wrapper {
    margin-left: $space * 6;
    margin-bottom: $space * 6;
    padding: ($space * 8) ($space * 6) ($space * 8) 0;
    display: block;

    @include border(top, $color: $grey--light);
    @include border(bottom, $color: $grey--light);

    @include media(md) {
        display: flex;
    }
}

;@import "sass-embedded-legacy-load-done:1037";