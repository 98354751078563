/* ------------------------------------ *\
    MODAL BOX
\* ------------------------------------ */

.modal {
    &__close {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        top: 50%;
        right: 0;
        width: 40px;
        height: 40px;
        transform: translateY(-50%);
        color: $blue;

        &:hover {
            text-decoration: none;
        }

        &::after {
            content: $fa-var-close;
            font-family: $font-family--font-awesome;
            font-size: $font-size--medium;
            cursor: pointer;
        }

        &--top {
            left: auto;
            top: 20px;
            width: 40px;
            height: 40px;
            color: $blue;
        }

        &--white {
            background-color: transparent;
            border: 0;
            font-size: 20px;
            padding: $space ($space * 2);
            color: #fff;
        }

        &--grey {
            color: $base-text-color;
        }
    }

    &__overlay {
        position: fixed;
        display: flex;
        opacity: 1;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        background: rgba(#000, 0.3);
        cursor: pointer;
        z-index: $z-modal;

        &--top-priority {
            z-index: $z-max;
        }

        [data-close] * {
            pointer-events: none;
        }

        &--animated-forward {
            animation: modalOverlayOpen 0.3s forwards;

            .modal__body {
                animation: modalBodyOpen 0.3s forwards;
            }
        }

        &--animated-reverse {
            animation: modalOverlayClose 0.3s forwards;

            .modal__body {
                animation: modalBodyClose 0.3s forwards;
            }
        }
    }

    &__image-overlay {
        position: relative;
        width: 98%;
        max-width: 1000px;
        min-height: $space * 11;
        max-height: 100vh;
        padding: ($space * 3 + 42) ($space * 3) ($space * 6);
        background: #fff;
        cursor: auto; //in case the modal is inside the overlay
    }

    &__zoom-image {
        max-height: 70vh;
    }

    &__blend {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: $z1;
    }

    &--exit-intent {
        width: auto;

        .modal__close {
            left: auto;
            right: 0;
            &::after { font-size: $font-size--large; }
        }

        .modal__coupon-code,
        .modal__phone-number { color: $yellow--dark; }

        .modal__phone-number { font-size: $font-size--medium; }
        .modal__coupon-code { font-size: $font-size--xlarge; }

        .modal__continue-button {
            display: block;
            width: 160px;
            height: 30px;
            background-color: transparent;
            border: none;
            color: $theme-color;
        }

        .modal__image { @include border($color: #fff); }
    }
}

@include animFade(modalOverlayOpen, 0, 1);
@include animFadeAndTranslate(modalBodyOpen, -70, 0, 0, 1);
@include animFade(modalOverlayClose, 1, 0, true);
@include animFadeAndTranslate(modalBodyClose, 0, -70, 1, 0, true);

;@import "sass-embedded-legacy-load-done:894";