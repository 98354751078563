.customer-account__table--offer {
    .button--inverted {
        font-weight: $font-weight--bold;
        margin: ($space * 2) ($space * 4) ($space * 2) 0;
        width: calc(100% + 10px);

        i {
            color: $theme-color;
            font-size: 20px;
            margin-right: $space * 2;
        }

        &:hover i {
            color: #fff;
        }

        @include media(lg) {
            margin: $space ($space * 4);
            width: auto;
        }

        @include media(xl) {
            margin: 1px 0;
            width: auto;
        }
    }
}

;@import "sass-embedded-legacy-load-done:685";