input[type="checkbox"] {
    + span {
        border-radius: $border-radius;
        border-color: $border-default-color;

        &::before {
            font-family: FontAwesome;
            content: $fa-var-check;
            color: #fff;
        }
    }

    &:hover + span {
        border-color: $theme-color;
    }

    &:checked + span {
        background-color: $theme-color;
        border-color: $theme-color;

        &::before {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:778";