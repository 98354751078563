/* ------------------------------------ *\
    PDP
\* ------------------------------------ */

.pdp__banner {
    margin: ($space * 3) 0 ($space * 6) 0;

    a {
        line-height: 0;
    }
}

.pdp__main-img,
.pdp__main-info {
    position: relative;
    width: 100%;
}

.pdp__main-img {
    @include media(lg) {
        width: 38%;
    }
    @include media(xl) {
        width: 35.5%;
    }

    &--picture {
        @include media(md) {
            height: $image-size-xm;
        }

        @include media(xl) {
            height: $image-size-xm + 40;
        }

        @include media(xxl) {
            height: $image-size-lg;
        }
    }

    &--slider {
        height: $image-size-lg;
    }
}

.pdp__main-info {
    &__title {
        margin-bottom: $space * 2;
    }

    .product-specs {
        @include border('bottom');
    }

    @include media(lg) { width: 62%; }
    @include media(xl) { width: 59%; }
}

.pdp__main-img__preview {
    position: relative;

    img {
        display: block;
        max-height: 320px;
        max-width: 320px;
        margin: 0 auto;

        @include media(lg) {
            max-width: 100%;
        }
    }

    & > .badge {
        position: absolute;
        top: 0;
        right: 0;
    }

    .a-play-button {
        display: block;
        margin: 0 auto;
    }
}

.pdp__img__thumb {

    & > .circle-dot {
        width: 13px;
        height: 13px;
        display: block;
        border-radius: 50%;
        background: $grey;
    }

    &.active {

        & > img,
        & > img:hover { border-color: $grey--darker; }

        & > .circle-dot,
        & > .circle-dot:hover { background: $grey--darker; }
    }
}

.pdp__img__thumbs--zoom-mobile {
    position: absolute;
    bottom: 0;
    left: $space;
}

.pdp__img__nav {
    & > .btn--nav { position: absolute; }
    & > .btn--nav--left { left: 0; }
    & > .btn--nav--right { right: 0; }
}

.product-detail__zoom-lens {
    position: absolute;
    z-index: $z2;
    background-color: $theme-color;
    opacity: 0.2;
}

.product-detail__zoom-tracker {
    position: absolute;
    z-index: $z2;
    display: none;

    @include media(lg) {
        display: block;
        cursor: pointer;
    }
}

.pdp__main-img__zoom {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $z2;
    width: 300px;
    height: 300px;
    overflow: hidden;

    & > img {
        position: absolute;
        max-width: none;
    }
}

//Refactored version: v2/components/_product-buy-box.organism.scss
.pdp__buy-box {
    position: relative;

    .quantity-field__container {
        position: absolute;
        top: 0;
        right: 0;
        .tooltip { min-width: 100%; }

        @include media(lg) {
            position: relative;

            .tooltip {
                min-width: 180px;
                left: -30px;
            }
        }
    }

    .btn--stapler:hover {
        color: #fff;
        border-color: $yellow;
        background-color: $yellow;

        & > .message { display: none; }

        & > .message--hover {
            display: inline-block !important;
            visibility: visible;
        }
    }

    .loading &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.4);
    }
}

.pdp__rating-container {
    max-height: 280px;
    overflow: auto;
}

.pdp-collapsible-block.collapsed {
    display: none;
    @include media(lg) { display: block; }
}

.pdp__coupon-info {
    margin: -$space (-$space * 3);
    padding: 0 ($space * 3);
    @include border($where: 'bottom', $width: 1px, $color: $grey);

    &__icon .fa {
        color: $blue;
        font-size: 20px;
    }

    &__voucher {
        font-weight: bold;
        color: $yellow;
    }
}

/* ------------------------------------ *\
    PDP ZOOM FEATURE
\* ------------------------------------ */
.pdp__zoom__close {
    border: 0;
    position: absolute;
    top: $space * 2;
    right: $space * 3;
}

.pdp__zoom--mobile__container {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 100%;
    transform: translateY(-50%);
}

.pdp__zoom--position {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pdp__zoom--prev {
    transform: translate3d(-100%, 0, 0);
}

.pdp__zoom--current {
    transform: translate3d(0%, 0, 0);
}

.pdp__zoom--prev--next {
    transform: translate3d(100%, 0, 0);
}

.pdp__zoom--mobile__img {
    position: relative;
    top: 0;
    left: 0;
    max-width: none;
}

.pdp__zoom--position,
.pdp__zoom--mobile__img {
    &.animate {
        transition: all 0.3s $transition-ease;
    }
}

.pdp__zoom--mobile__img-preloader {
    position: fixed;
    left: 1000%;
    width: 99999em;
}

.pdp__sales-info__image {
    border-radius: 50%;
    flex: none;
    height: 40px;
    margin-right: $space * 3;
    width: 40px;
}

.loading {
    @include media(lg) {
        .pdp-product-description__placeholder {
            display: flex;
            min-height: 235px;
        }

        .pdp_technical-data__placeholder {
            display: flex;
            min-height: 220px;
        }

        .pdp-related-products__placeholder {
            display: flex;
            min-height: 478px;
        }
    }
}

.pdp-loadable__placeholder {
    align-items: center;
    display: flex;
    justify-content: center;
    min-height: 100px;
}

.pdp__sticky-add-to-cart {
    background: #fff;
    bottom: -90px;
    box-shadow: $shadow-top-only;
    max-height: 90px;
    padding: $space * 3;
    position: fixed;
    transition: bottom 0.2s;
    width: 100%;

    &-placeholder {
        background: $grey--darker;
        display: none;
        height: 85px;

        &--stuck {
            display: block;
        }
    }

    &--stuck {
        bottom: 0;
    }

    &-button {
        width: 100px;
    }

    &-image {
        max-height: 48px;
        max-width: 48px;
    }

    &-price {
        line-height: $line-height--large-plus;
    }
}

;@import "sass-embedded-legacy-load-done:760";