//Refactors: styles/v1/components/_product-widget.scss

/* ------------------------------------ *\
    PRODUCT WIDGET - XL
\* ------------------------------------ */
@include media(xl) {
    .o-product-widget-v2 {
        &__buy-block--list-view {
            align-items: center;
            flex-direction: row;
            padding-right: 0;
            width: percentage(calc(1 / 2));
        }

        &__prices--list-view {
            width: 140px;
            align-items: flex-start;
            padding: 0 $space * 2 0 $space * 3;
            text-align: initial;
        }

        &__info--list-view {
            width: percentage(calc(1 / 2));
        }

        &--list-view {
            padding-top: $space * 4;
            padding-bottom: $space * 4;
        }
    }
}


;@import "sass-embedded-legacy-load-done:1249";