/* ------------------------------------ *\
    JOBS PAGE
\* ------------------------------------ */

.page-jobs-index {
    .slider__item {
        min-width: 100%;
    }

    @include media(md) {
        .slider__item {
            min-width: 50%;
        }
    }

    @include media(lg) {
        .slider__item {
            min-width: percentage(calc(1 / 3));
        }
    }
}

.jobs__car__icon {
    min-width: 150px;
    min-height: 150px;
    border-width: 6px;

    & > svg {
        height: 67px;
        fill: $theme-color;
    }
}

.jobs-header {
    position: relative;

    &__image {
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        height: 300px;
        position: relative;
        width: 100%;
    }

    &__headline {
        background-color: $blue;
        color: #fff;
        display: flex;
        flex-direction: column;
        height: 100px;
        justify-content: center;
        margin-top: -150px;
        padding: 60px 0 130px;
        position: relative;
        text-align: center;

        span {
            font-size: 14px;
            margin: $space * 4;
        }

        h1 {
            font-family: $font-family--main;
            font-size: 30px; // special size for jobs page
        }
    }
}

.jobs-description {
    margin-top: -60px;

    &__list {
        padding: ($space * 4);

        & > ul {
            padding: ($space * 4) 0;

            li {
                margin-left: $space;
                position: relative;

            }

            li::before {
                content: '-';
                position: absolute;
                left: -($space * 2);
            }
        }
    }
}

.jobs-footer {
    text-align: center;

    .button {
        margin-top: $space * 2;
    }
}

@include media(lg) {
    .jobs-header__image {
        height: 400px;
    }

    .jobs-header__headline {
        span {
            font-size: $font-size--large;
            margin: $space * 4;
        }

        h1 {
            font-size: 50px; // special size for jobs page
        }
    }
}

@include media(xl) {
    .jobs-header__image {
        height: 600px;
    }
}

.jobs-apply-header {
    &__headline {
        background-color: $blue;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100px;
        padding: ($space * 12) 0 ($space * 26) 0;
        text-align: center;

        span {
            font-size: 14px;
            margin: $space * 4;
        }

        h1 {
            font-family: $font-family--main;
            font-size: 30px; // special size for jobs page
        }
    }
}

.jobs-apply-container {
    margin-top: -60px;
}

.language-switch {
    display: flex;
    width: 100px;
    margin: ($space * 2) 0;

    &__text.active {
        color: $theme-color;
    }
}


.jobs-banner {
    width: 100%;
    margin-top: $space * 2;

    &__widget {
        @include border(bottom, $color: $grey);
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
        background-color: white;
    }

    &__link {
        line-height: 0;
    }
}

.fluid-video {
    width: 100%;
    max-width: 680px;
    margin: ($space * 2) auto;

    &__container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: $space * 6;
        height: 0;
        overflow: hidden;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:754";