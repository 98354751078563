@include media(lg) {
    .o-product-teaser {
        &__header h2 {
            margin: 0 0 ($space * 14);
            font-size: $font-size--xlarge-plus;
            line-height: 43px;
        }
        &__element {
            [class*='link'] {
                @include multi-line-ellipsis(185px, 2);
            }
            &--four,
            &--six {
                width: calc(50% - 10px);
                [class*='link'] {
                    @include multi-line-ellipsis(305px, 2);
                }
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1354";