.o-customer-identification {
    width: 100%;

    &__body {
        padding: $space * 3 $space * 3 $space * 4;
        margin: $space * 4 0;
        box-shadow: $panel-shadow !important;
        border-radius: $border-radius;
    }

    &__header {
        padding: 0;
        border-bottom: 1px solid $grey;
    }

    &__header-text {
        padding-bottom: $space * 3;
    }

    &__container {
        padding: 0;
    }

    &__checkbox {
        margin: $space * 4 0;

        label::after {
            content: "";
        }
    }

    &__email-label {
        margin-bottom: $space;
    }

    &__email-text {
        &::after {
            content: "*";
            font-weight: 600;
            color: $blue;
        }
    }

    &__email {
        display: flex;
        flex-direction: column;
    }

    &__required-info {
        display: flex;
        flex-direction: row-reverse;
        margin-top: $space * 3;

        &-text {
            color: $grey--dark;
        }
    }

    &__wrapper {
        width: 100%;
        border-radius: 2px;
    }
}

;@import "sass-embedded-legacy-load-done:1428";