.o-sponsored-label-modal {
    display: none;

    .show {
        display: block;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding: 0 $space * 6 $space * 6;
        width: 100%;
        height: 100%;
        max-height: unset;
    }

    &__header {
        word-wrap: break-word;
        padding: $space * 4 $space * 3 0 0;
    }

    &__product {
        flex-grow: 1;
        padding-top: $space * 4;
    }

    &__title {
        padding-top: $space * 4;
        border-top: none;

        button {
            font-size: 24px;
        }
    }

    &__header,
    &__product,
    &__button-container {
        flex-shrink: 0;
    }

    &__button {
        width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:1161";