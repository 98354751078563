.toggle-filter {
    &__title {
        padding: 3 * $space;
    }

    &__button {
        width: 100%;
    }

    &__item {
        border-bottom: 1px solid $grey;

        &-radio-field {
            padding: 3 * $space;
            padding-left: 0;
            margin-left: 3 * $space;

            &:first-child {
                margin-left: 3 * $space;
            }
        }

        &-label {
            width: 100%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:732";