$pannel-width: 100%;
$margin-top: 10 * $space;

.t-pdp {

    &__above-the-fold-container {
        flex-direction: column;
        width: 100%;
        display: flex;
    }

    &__product-gallery,
    &__buy-box {
        margin-bottom: $space * 3;
        width: $pannel-width;
    }

    &__bought-together,
    &__recommendations,
    &__description,
    &__documents,
    &__related_products,
    &__technical-data,
    &__comparison,
    &__rich-content,
    &__reviews,
    &__usps,
    &__last-seen {
        &:empty {
            display: none;
        }
    }
    &__description {
        .t-panel__body {
            h2 {
                font-size: $font-size--medium;
                font-weight: $font-weight--bold;
            }
        }
    }
    &__usps {
        display: flex;
        background-color: $grey--darker;
        margin-top: $margin-top;
        @include grow-outside-container;
    }
}

// Remove: Only for doumentation. Remove once all placeholders are replaced with components
.m-pdp__dummy-placeholder {
    display: flex;
    align-items: center;
    background: $grey--medium;
    justify-content: center;
}

.a-txt--grey {
    color: $grey--dark;
}
// End remove: Only for doumentation Remove once all placeholders are replaced with components

;@import "sass-embedded-legacy-load-done:1394";