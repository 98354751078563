.m-title-header {
    align-items: center;
    display: flex;

    &__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &--padded {
            padding: $space * 3;
        }

        &--padded-wide {
            padding: ($space *3) 0;
        }

        &--success {
            background: $green;
            color: #fff;
        }

        &--error {
            background: $red;
            color: #fff;
        }

        &--warning {
            background: $yellow;
            color: #fff;
        }

        &--grey {
            background-color: $grey--light;
            color: $grey--darker;
        }

        &--default {
            color: $grey--darker;
            @include border($where: top);
        }
    }

    &__left-icon {
        color: $grey;
        font-size: $font-size--medium;
        margin-right: $space;
    }

    &__button--right {
        background: transparent;
        border: none;
        color: inherit;
        height: $line-height--regular;
        padding: 0;

        &:hover {
            color: inherit;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1043";