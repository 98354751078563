//Product text styles
.a-txt {
    &__price {
        color: $red;
        font-family: $font-family--main;
        font-size: $font-size--xlarge;
        line-height: $line-height--xlarge;
    }

    &__price--small {
        color: $red;
        font-size: $font-size--regular;
        font-weight: $font-weight--bold;
        line-height: $line-height--regular;
    }

    &__price--large {
        color: $red;
        font-family: $font-family--main;
        font-size: $font-size--large;
        line-height: $line-height--large-plus;
    }

    &__delivery--green {
        color: $green;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
    }

    &__delivery--grey {
        color: $grey--dark;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
    }

    &__delivery--yellow {
        color: $yellow;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
    }

    &__delivery--red {
        color: $red;
        font-size: $font-size--small;
        font-weight: $font-weight--bold;
    }

    &__hint--grey {
        color: $grey--dark;
        font-weight: $font-weight--bold;
    }

    &__uvp--striked-strong {
        font-weight: $font-weight--bold;
        text-decoration: line-through;
    }

    &__uvp--striked {
        text-decoration: line-through;
    }

    &__title {
        font-size: $font-size--regular;
        font-weight: $font-weight--bold;
        line-height: $line-height--regular;
    }

    &__title-primary--responsive {
        font-size: $font-size--regular-plus;
        line-height: $line-height--regular;

        .fa {
            display: none;
        }
    }

    &__title-primary {
        font-family: $font-family--main;
        font-size: $font-size--large;
        font-weight: $font-weight--bold;
        line-height: $line-height--large;
    }

    &__title--two-liner {
        font-size: $font-size--regular;
        font-weight: $font-weight--bold;
        line-height: $line-height--regular;
        height: $line-height--regular * 2;
        overflow-y: hidden;
    }

    &__title--large {
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;
        line-height: $line-height--regular;
    }

    &__subtext {
        color: $grey--dark;
        font-size: $font-size--small;
        line-height: $line-height--small;

        &--bold {
            color: $grey--dark;
            font-size: $font-size--regular;
            font-weight: $font-weight--bold;
        }
    }

    &__article-number {
        @include truncate-text;
        color: $grey--dark;
        font-size: $font-size--small;
    }

    &--highlighted-grey {
        background: $grey--light;
    }

    //PDP V2 styles
    &--h1 {
        font-family: $font-family--main;
        font-size: $font-size--xlarge-plus;
        font-weight: $font-weight--base;
    }

    &--small {
        font-size: $font-size--small;
    }

    &--regular {
        font-size: $font-size--regular;
    }

    &--regular-plus {
        font-size: $font-size--regular-plus;

        &-bold {
            @extend .a-txt--regular-plus;
            font-weight: $font-weight--bold;
        }
    }

    &--medium {
        font-size: $font-size--medium;

        &-bold {
            @extend .a-txt--medium;
            font-weight: $font-weight--bold;
        }

        &-bold-two-liner {
            @extend .a-txt--medium-bold;
            height: $line-height--regular * 2;
            overflow-y: hidden;
        }
    }

    &--medium-plus {
        font-size: $font-size--medium-plus;

        &-bold {
            @extend .a-txt--medium-plus;
            font-weight: $font-weight--bold;
        }

    }

    &--subtext-v2 {
        color: $grey--dark;
        font-size: $font-size--regular-plus;

        &-small {
            @extend .a-txt--subtext-v2;
            font-size: $font-size--small;
        }
    }

    &--product-name {
        &::before {
            height: 2px;
            content: '';
            display: block;
        }
        @extend .a-txt--h1;
        font-size: $font-size--xlarge;
        line-height: 36px;
        overflow: hidden;
        word-break: break-word;

        @include line-clamp(3);

        @include media(md) {
            font-size: $font-size--xlarge;
            line-height: 36px;
        }

        &.sticky-product-name-abtest29887 {
            @extend .a-txt__title-primary;
            @include multi-line-ellipsis(328px, 2);
        }
    }

    &--product-stock {
        font-size: $font-size--medium;
        font-weight: $font-weight--bold;
        padding-left: $space *2;
    }

    &--uvp-v2 {
        text-decoration: line-through;
        font-size: $font-size--small;

        &-bold {
            @extend .a-txt--uvp-v2;
            font-weight: $font-weight--bold;
        }

        &-background-bold-small {
            @extend .a-txt--uvp-v2;
            background-color: $red;
            color: white;
            font-size: $font-size--small;
            font-weight: $font-weight--bold;
            padding: 2px 5px;
        }

        &-bold-regular-plus {
            @extend .a-txt--uvp-v2;
            font-size: $font-size--regular-plus;
            font-weight: $font-weight--bold;
        }

        &-striked {
            @extend .a-txt--uvp-v2;
            text-decoration: line-through;
        }

        &-striked-bold {
            @extend .a-txt--uvp-v2-striked;
            font-weight: $font-weight--bold;

            &-regular-plus {
                @extend .a-txt--uvp-v2-striked-bold;
                font-size: $font-size--regular-plus;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1169";