/** TODO: check why !important is needed */
input[type="radio"],
input[type="checkbox"] {
    background-image: none !important;
    border: 0 !important;
    box-shadow: none !important;
    cursor: pointer;
    height: 20px !important;
    min-width: 20px !important;
    min-height: 20px !important;
    padding: 0 !important;
    width: 20px !important;
    opacity: 0;

    + span {
        @include border($width: 2px, $color: $grey);
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        max-width: 20px;
        min-width: 20px;
        max-height: 20px;
        min-height: 20px;
        border-radius: 50%;
        background: #fff;
        user-select: none;
        pointer-events: none;
        justify-content: center;
        align-items: center;

        &::before {
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    a:hover & + span,
    &:hover + span {
        border-color: $theme-color;
    }

    &:checked + span {
        border-color: $theme-color;

        &::before {
            display: block;
        }
    }
}

;@import "sass-embedded-legacy-load-done:770";