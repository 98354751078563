/* ------------------------------------ *\
    CUSTOMER ACCOUNT SUBHEADER
\* ------------------------------------ */

.ca-subheader {
    background-color: #fff;
    height: 50px;

    .container {
        align-items: center;
    }

    &,
    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .page-order-details-orderdetails & {
        height: 230px;
    }

    .page-offer-details-offerdetails & {
        height: 180px;
    }

    .customer-account-shopping-list & {
        height: 70px;
    }

    .customer-account-customer-account-settings &,
    .customer-account-customer-user-management &,
    .customer-account-profile & {
        display: none;
        height: 40px;

        a {
            margin-right: $space * 8;
        }
    }

    .page-orders-orders & {
        height: 100px;
    }

    .page-offers-offers & {
        height: 180px;
    }

    .page-items-indexlist & {
        height: 160px;
    }

    .icon--pdf {
        margin-right: $space;
        font-size: $font-size--regular-plus;
    }

    hr {
        @include border(bottom, $color: $grey--light);
    }
}

@include media(lg) {
    .ca-subheader {
        height: 80px;
        .page-offer-details-offerdetails & {
            height: 130px;
        }

        // revert lg styles from _select.scss
        select,
        input {
            height: 40px;
            padding-top: $space * 2;
            padding-bottom: $space * 2;
        }

        select {
            background-position: calc(100% - 20px);
        }

        .page-orders-orders & {
            height: 70px;
        }

        .page-offers-offers & {
            height: 130px;
        }

        .customer-account-customer-account-settings &,
        .customer-account-customer-user-management &,
        .customer-account-profile & {
            display: flex;
        }

        .page-items-indexlist & {
            height: 130px;
        }
    }
}

@include media(xl) {
    .ca-subheader {
        .page-offer-details-offerdetails &,
        .page-order-details-orderdetails & {
            height: 150px;
        }
    }
}

@include media(xxl) {
    .ca-subheader {
        .page-order-details-orderdetails &,
        .page-offer-details-offerdetails &,
        .page-offers-offers &,
        .page-items-indexlist & {
            height: 96px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:666";