@include media(md) {
    .t-product-highlight {
        padding-top: ($space * 14);
        padding-bottom: ($space * 10);

        &__header {
            margin-bottom: $space * 8;
            font-size: $font-size--xlarge-plus;
        }

        &__main-content,
        &__sub-content:first-child {
            margin-bottom: $space * 6;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1400";