.m-full-size-banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 230px;
    justify-content: center;
    align-items: center;
    padding: ($space * 8) 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;

    &__headline {
        text-align: center;
    }

    &__button {
        padding-top: ($space * 6);
    }

    &__btn {
        min-width: 274px;
    }

    &--light {
        background-image: url(/images/banner-fallbacks/concrete-mobile.png);
    }

    &--dark {
        background-color: $grey--darker;
        color: white;
    }
}

;@import "sass-embedded-legacy-load-done:1047";