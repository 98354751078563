.o-product-bundle-components {
    &__headline {
        margin-bottom: $space * 2;
    }

    &__item {
        display: flex;
        margin-bottom: $space;
    }

    &__item-text {
        margin-left: $space;
        vertical-align: top;
    }
}

;@import "sass-embedded-legacy-load-done:1389";