.m-infospace {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    min-width: 280px;
    width: 100%;
    padding: ($space * 8) ($space * 8) ($space * 14) ($space * 8);
    background-color: $grey--light;

    &__image {
        margin: 0 auto;
    }

    &__content {
        margin-top: $space * 6;
    }

    &__headline {
        font-size: $font-size--medium-plus;
    }

    &__text {
        margin-top: $space * 2;
        font-size: $font-size--medium;
        line-height: $line-height--large;
    }

    &__link {
        font-size: $font-size--medium;
        text-decoration: none;
        color: $blue;
        margin-top: $space * 3;
        font-weight: $font-weight--bold;
    }
}

;@import "sass-embedded-legacy-load-done:1007";