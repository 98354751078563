.products-with-attributes {
    background-color: #fff;
    box-shadow: $shadow6;

    &__headline {
        padding: $space * 3;
        background-color: rgba(39, 58, 96, 0.7);
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        color: #fff;
        text-align: center;
    }

    &__product {
        display: flex;
        flex-wrap: wrap;
    }

    &__product-image,
    &__product-data,
    &__product-add-to-cart {
        width: 100%;
    }

    &__product-image {
        display: flex;
        padding: $space * 2;
        justify-content: center;

        img {
            max-width: 200px;
        }
    }

    &__product-data {
        padding: $space * 3;

        h3 a {
            color: #273a60; // special color
            font-family: $font-family--main;
            font-size: $font-size--large;
            line-height: $line-height;
        }

        table {
            margin-top: $space * 3;
        }
    }

    &__add-to-cart {
        display: flex;
        width: 100%;
        padding: $space * 3;
        flex-wrap: wrap;
        justify-content: flex-end;

        .quantity-field__container {
            margin-top: 0 !important;
        }
    }

    &__prices,
    &__form {
        width: 100%;
    }

    &__prices {
        min-width: 105px;
    }

    &__form {
        margin-top: $space * 2;
    }

    &__product + &__product {
        @include border(top);
        margin-top: $space * 3;
    }

    @include media(md) {
        &__prices,
        &__form {
            width: auto;
        }

        &__form {
            margin-top: 0;
            margin-left: $space * 6;
        }
    }

    @include media(lg) {
        &__product-image {
            width: percentage(calc(4 / 12));
        }

        &__product-data {
            width: percentage(calc(8 / 12));
            padding-left: $space;
        }
    }

    @include media(xl) {
        &__product-image {
            width: percentage(calc(6 / 24));
        }

        &__product-data {
            width: percentage(calc(12 / 24));
        }

        &__add-to-cart {
            width: percentage(calc(6 / 24));
            justify-content: flex-start;
        }

        &__form {
            margin-left: 0;
            margin-top: $space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:523";