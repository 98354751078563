//Product text styles
@include media(lg) {
    .o-order-overview {
        &__email {
            position: absolute;
            left: 0;
            top: 60%;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1393";