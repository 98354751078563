@include media(lg) {
    .o-logo-pool {
        &__container {
            grid-template-columns: repeat(6, 1fr);
            gap: ($space * 2) ($space * 3);

            &__item {
                padding: $space * 2;
            }
        }

        &__logo {
            width: 91px;
            height: 46px;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1414";