.navigation {
    background-color: rgba(#000, 0.2);
    color: $base-text-color;
    display: none;
    font-size: $font-size--regular;
    height: 100%;
    left: 0;
    line-height: $line-height;
    position: fixed;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: $z4;

    &__title {
        @include border(bottom, $color: $grey--light);
        align-items: center;
        color: $base-text-color;
        display: flex;
        font-weight: $font-weight--bold;
        justify-content: space-between;
        min-height: 40px;
        padding: 0 ($space * 3);

        .fa {
            color: $theme-color;
            font-size: 24px;
            pointer-events: none;
        }
    }

    .menu {
        top: 0;

        &__list-item i {
            color: $grey--dark;
        }

        &__list-item .fa-spinner {
            display: none;
        }

        &__list-item .fa-chevron-right,
        &__list-item--back .fa {
            color: $grey;
        }

        &__list-item--more {
            display: none;
        }

        &__list-item--more .fa {
            color: $theme-color;
            width: 64px;
        }

        &__list-item-name {
            max-width: 200px;
        }

        &__list-item--top-brands-title {
            align-items: center;
            display: flex;
            height: 60px;
            padding-left: $space * 3;
            text-transform: uppercase;
        }

        &__list-item--small {
            padding-left: $space * 3;
        }

        &__list-item--small a::after {
            color: $grey;
            content: $fa-var-chevron-right;
            font-family: "FontAwesome";
            font-size: 16px;
            margin-left: auto;
            text-align: center;
            width: 40px;
        }

        &__list-item--top-brands-title::after,
        &__list-item--small::after {
            left: $space * 3;
        }
    }

    .loading .fa-chevron-right {
        color: $theme-color;
        display: none !important;
    }

    .loading .fa-spinner {
        display: flex !important;
        color: $theme-color;

        &::before {
            animation: fa-spin 2s infinite linear;
        }
    }

    &.active {
        display: block;
    }

    .menu,
    .menu__list,
    .service,
    .specials {
        position: absolute;
        left: 0;
        height: 100%;
        max-width: 280px;
        min-width: 280px;

        &[data-level="2"] {
            z-index: $z2;
        }

        .fa-chevron-right,
        .fa-spinner {
            font-size: 16px;
        }
    }

    .menu__list,
    .service,
    .specials {
        top: 40px;
        background-color: #fff;
        height: calc(100% - 40px);
        overflow: auto;
    }

    .service,
    .specials {
        h3 {
            font-size: $font-size--regular;
            font-weight: $font-weight--bold;
            line-height: 40px;
            padding-left: $space * 3;
        }

        &__item {
            display: flex;
            padding: $space * 3;
            position: relative;
            color: inherit;

            a {
                color: $base-text-color;
                text-decoration: none;
            }

            .fa:first-child,
            [class^="icon--"] {
                color: $grey--dark;
                min-width: 35px;
            }

            .fa:first-child {
                font-size: 20px;
            }

            [class^="icon--"] {
                font-size: 22px;
            }

            .fa-spinner,
            .fa-chevron-right {
                align-self: center;
                margin-left: auto;
                color: $grey;
            }

            .fa-spinner {
                display: none;
            }

            &:hover {
                text-decoration: none;
            }

            &:hover a,
            &:hover .fa,
            &:hover [class^="icon--"] {
                color: $theme-color;
            }

            &::after {
                @include border(bottom, $color: $grey--light);
                bottom: 0;
                content: "";
                display: block;
                left: 50px;
                position: absolute;
                right: 0;
            }
        }
    }

    .service {
        &__item {
            &--contact {
                background-color: $grey--light;
                min-height: 130px;
            }

            &--contact img {
                bottom: 0;
                display: none;
                position: absolute;
                right: 0;
            }

            &--contact::after {
                display: none;
            }
        }
    }

    .specials {
        &__advertising a {
            display: flex;
            align-items: flex-end;
        }
    }
}

@include media(lg) {
    .navigation {
        height: auto;
        position: relative;
        top: 0;
        width: auto;
        z-index: $z2;

        &.fixed {
            position: relative;
        }

        &__title {
            display: none;
        }

        .menu,
        .menu__list,
        .service,
        .specials {
            position: static;
            height: auto;
            max-width: 324px;
            min-width: 324px;
        }

        .specials__advertising {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .menu {
            display: flex;
            flex-wrap: nowrap;
            max-width: none;
            position: absolute;
        }

        .menu__list-item a {
            align-items: center;
            height: 40px;
            padding: 0;

            &:hover {
                color: $theme-color;
            }
        }

        .menu__list {
            background-color: #fff;
            overflow: visible;
            z-index: $z1;
            height: fit-content;

            &[data-level="1"] {
                box-shadow: 0 0 20px rgba(#000, 0.2);
            }
        }

        .menu__list-item-name {
            max-width: 240px;
        }

        .menu__list-item--more {
            display: block;
        }

        .menu__list[data-level="2"],
        .service[data-level="2"],
        .specials[data-level="2"] {
            z-index: 0;
        }

        .service__item--contact {
            a {
                z-index: 1;
            }

            img {
                display: block;
            }
        }

        .menu__list-item--top-brands-title {
            height: 40px;
        }

        .menu__list-item--small a {
            height: auto;
        }

        .menu__list-item--small a::after {
            display: none;
        }

        .menu__list-item--top-brands-title::after,
        .menu__list-item--small::after {
            display: none;
        }

        .menu__list-item--small:last-child {
            padding-bottom: $space * 2;
        }

        .menu__list-item:hover {
            .fa-chevron-right,
            [class^="icon--"]:last-child {
                color: $blue;
            }
        }

        [data-level="2"] .fa-chevron-right {
            display: none;
        }

        [data-level="2"] {
            box-shadow: $shadow2;
        }
    }
}

@include media(xxl) {
    .navigation.fixed {
        display: block;
    }
}

#navigation .menu {
    @include media(lg) {
        background-color: transparent;
    }
}

.new-mobile-navigation {
    &.show-filters .cat-filters {
        top: 0;

        &__container,
        &__header,
        .close-filters {
            top: 0;
        }

        &__footer {
            top: calc(var(--vh, 1vh) * 100 - 188px);
        }

        &__content {
            top: 48px;
            height: calc(var(--vh, 1vh) * 100 - 236px);
        }

        .cat-filters-details {
            top: 0;

            &__footer {
                top: calc(var(--vh, 1vh) * 100 - 188px);
            }

            .cat-filter__content {
                max-height: calc(var(--vh, 1vh) * 100 - 236px);
            }
        }
    }

    &.show-categories .cat-tree {
        top: 0;

        &__footer {
            top: calc(var(--vh, 1vh) * 100 - 188px);
        }
    }
}

;@import "sass-embedded-legacy-load-done:752";