.product-relation-highlight {
    width: 100%;
    border-radius: $border-radius;

    @include media(lg) {
        @include border();
    }

    &__image {
        position: relative;
        padding-right: $space * 2;
        text-align: center;

        a {
            width: 100%;
        }

        img {
            min-height: 80px;
            max-height: 180px;
        }

        @include media(lg) {
            padding: 0;
            width: 100%;

            img {
                display: block;
                margin: 0 auto;
                height: 180px;
            }
        }
    }

    &__content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        > a {
            padding: 0 ($space * 3);
        }

        @include media(lg) {
            width: 100%;
            align-items: center;

            > a {
                padding: $space ($space * 3);
            }
        }
    }

    &__title {
        font-weight: $font-weight--bold;
        font-size: $font-size--regular;
        line-height: $line-height--small;

        @include media(lg) {
            font-family: $font-family--main;
            font-size: $font-size--large;
            line-height: $line-height--large;
        }
    }

    &__details {
        @include truncate-text;

        font-size: $font-size--small;
        padding: 0 ($space * 2);
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    &__manufacturer-img {
        width: 100%;
        text-align: center;
    }

    &__buy-box {
        position: relative;
        margin: inherit;
        padding: 0 ($space * 3);
        width: 100%;

        @include media(lg) {
            padding-top: $space * 3;
        }
    }

    .quantity-field__container {
        display: none;

        @include media(lg) {
            display: block;
        }
    }

    .badge {
        position: absolute;
        top: 0;
        right: 0;
    }

    .product-widget__badge--second {
        .badge {
            top: $space * 5;
        }
    }

    @include media(lg) {
        .badge {
            position: absolute;
            top: $space * 2;
            right: $space * 2;
        }

        .product-widget__badge--second {
            .badge {
                top: $space * 7;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:489";