.o-campaign-card {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 4 * $space;
    margin-bottom: 4 * $space;

    &__image-container {
        position: relative;
        --padding: #{ 3 *  $space };
    }

    &__image {
        width: 100%;
        aspect-ratio: 2 / 1;
        object-fit: cover;
    }

    &__brand {
        position: absolute;
        top: var(--padding);
        left: 0;
        width: auto;
        aspect-ratio: 2 / 1;
        height: 60px;
        padding: $space 3 * $space;
        background-color: white;
        object-fit: contain;
    }

    &__eye-catcher {
        position: absolute;
        bottom: var(--padding);
        right: 0;
    }

    &__details {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    &__expiration-date {
        margin-bottom: 2 * $space;
        @include truncate-text;
    }

    &__title {
        margin-bottom: 3 * $space;
    }

    &__cta {
        height: 48px;
        margin-top: 8 * $space;
        color: white;
        width: 100%;
    }
}

;@import "sass-embedded-legacy-load-done:1074";