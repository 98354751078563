.ca-widget table,
.ca-table {
    th:last-child,
    td:last-child {
        text-align: right;
    }

    th:last-child,
    td:last-child,
    th:first-child,
    td:first-child {
        padding: $space * 3;
    }

    th {
        border: 0;
        background-color: $grey--lighter; //TODO: should be $grey--lighter if the page background color is $grey--light
        color: $grey--dark;
        vertical-align: middle;
    }

    td {
        vertical-align: top;
        background-color: #fff;
        border-color: $grey--light;

        &.ellipsis {
            max-width: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            a {
                display: inline;
            }
        }

        &.prices-column {
            width: 140px;
        }
    }

    i {
        color: $grey;
        font-size: 16px;
    }

    tfoot tr:first-child td {
        @include border(top);
    }
}

.ca-table {
    &--order,
    &--offer,
    &--shopping-lists,
    &--shopping-list {
        box-shadow: $shadow6;

        thead {
            display: none;
        }

        th,
        td {
            padding: $space * 3 !important;
        }

        td {
            vertical-align: top;
        }

        td:nth-child(2) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        th:last-child,
        td:last-child {
            text-align: left;
        }

        tfoot select {
            max-width: 100px;
        }

        &__item__remove {
            color: $grey;
            position: absolute;
            right: -20px;
        }

        &__item__remove--xxl {
            color: $grey;
            position: absolute;
            right: 15px;
        }

        &__select-item__remove {
            position: absolute;
            right: 0;
            top: 11px;
        }
    }

    &--order {
        td:nth-child(3),
        td:nth-child(5),
        td:nth-child(6),
        td:nth-child(7) {
            display: none;
        }

        .icon--pdf {
            font-size: 18px;
            margin-right: $space;
        }
    }

    &--offer {
        td {
            display: none;
            vertical-align: top;

            &:nth-child(1),
            &:nth-child(2) {
                display: table-cell;
            }
        }

        tr.not-pending td {
            color: $grey--dark;
        }
    }

    &--order-details {
        box-shadow: $shadow6;

        &__address-box {
            max-width: 350px;
        }

        th,
        td {
            position: relative;
            padding: $space * 3 !important;
        }

        td:last-child,
        th:last-child {
            text-align: left;
        }

        th .fa {
            position: absolute;
            right: $space * 2;
            top: 50%;
            transform: translateY(-50%);
            color: $theme-color;
        }

        th .fa-chevron-down {
            display: none;
        }

        th.collapsed .fa-chevron-down {
            display: block;
        }

        th.collapsed .fa-chevron-up {
            display: none;
        }

        td {
            vertical-align: top;
        }

        tbody.collapsed {
            display: none;
        }

        .js-collapsible {
            cursor: pointer;
        }

        + .ca-table--order-details {
            @include border(top, $color: $grey--light);
        }
    }

    &--offer-items {
        box-shadow: $shadow6;

        img {
            max-width: 48px;
            max-height: 48px;
        }

        th,
        td {
            padding: $space * 3;
        }

        tbody td:first-child {
            width: 1px;
        }

        tbody td:nth-child(2) {
            padding-left: 0;
        }

        thead,
        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5),
        tfoot td:first-child {
            display: none;
        }

        tfoot tr:first-child td {
            border-top: 0;
            background-color: $grey--lighter;
        }
    }

    &--users {
        th,
        td,
        td:first-child,
        td:last-child {
            padding: $space * 3;
        }

        td:nth-child(2) {
            padding-left: 0;
        }

        .fa-user,
        [class^="icon--"] {
            font-size: $font-size--medium-plus;
        }

        thead {
            display: none;
        }

        td {
            &:nth-child(1),
            &:nth-child(2) {
                display: table-cell;
            }

            display: none;
        }

        .txt--truncate {
            max-width: 180px;
        }
    }

    &--shopping-lists {
        td:nth-child(2) {
            padding-right: $space * 3 !important;
        }

        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5) {
            display: none;
        }
    }

    &--shopping-list {
        tbody td:first-child img {
            min-width: 70px;
        }

        thead {
            th:nth-child(3),
            th:nth-child(4) {
                min-width: 130px;
            }
        }

        tbody {
            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5) {
                display: none;
            }

            td:nth-child(2) {
                position: relative;
            }
        }

        tbody td:nth-child(2) {
            @include media(xl) {
                min-width: 130px;
            }
        }

        tfoot {
            td {
                background-color: $grey--lighter;
                border-top: 0 !important;
                padding: $space * 3 !important;
            }
        }
    }

    &--comment-icon {
        background-color: #fff;
        border-radius: 20px;
        color: $theme-color;
        font-size: 16px;
        position: relative;
    }
}

.ca-table--shopping-list--item {
    &__overlay {
        z-index: $z2;
    }

    td {
        padding-bottom: 0 !important;

        @include media(lg) {
            padding-bottom: 5px !important;
        }

        @include media(xxl) {
            padding-bottom: 15px !important;
        }
    }

    .item-quantity-selector {
        width: 150px;

        @include media(xl) {
            width: 110px;
        }

        @include media(xxl) {
            width: 120px;
        }

        select {
            width: 150px;

            @include media(xl) {
                width: 110px;
            }

            @include media(xxl) {
                width: 120px;
            }
        }
    }
}

.ca-table--shopping-list td {
    border: 0;

    &.bordered {
        @include border($where: bottom, $color: $grey--light);
    }
}

.ca-table--shopping-list--add-to-cart {
    td {
        padding-top: 0 !important;
    }

    td:last-child {
        display: table-cell !important;
    }
}


@include media(md) {
    .ca-table {
        &--shopping-lists {
            thead {
                display: table-header-group;
            }

            td {
                @include border($where: bottom, $color: $grey--medium);
            }

            td:nth-child(2) {
                padding-left: $space * 3 !important;
            }

            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5) {
                display: table-cell;
            }
        }
    }
}

@include media(lg) {
    .ca-table {
        &--order-details__address-box {
            max-width: 430px;
        }
    }
}

@include media(xl) {
    .ca-table {
        &--order-details__address-box {
            max-width: 640px;
        }

        &--offer,
        &--order-details,
        &--shopping-list {
            thead {
                display: table-header-group;
            }

            tbody {
                td:nth-child(3) {
                    min-width: 120px;
                }
            }

        }

        &--shopping-list td {
            @include border($where: bottom, $color: $grey--medium);
        }

        &--offer {
            th {
                vertical-align: top;
            }

            td {
                display: table-cell;
                vertical-align: middle;
            }
        }

        &--users {
            thead {
                display: table-header-group;
            }

            td {
                display: table-cell;
            }
            th,
            td,
            td:first-child,
            td:last-child {
                padding: $space * 2;
            }

        }

        &--shopping-list {
            thead th:nth-child(2) {
                display: none;
            }

            tbody {
                td:nth-child(4),
                td:nth-child(5) {
                    display: table-cell;
                }
            }

            tfoot {
                td:nth-child(1) {
                    display: table-cell;
                }

                td:nth-child(2) {
                    display: table-cell;
                }
            }

            .ca-list--summary-big {
                width: 205px;
            }
        }
    }
}

@include media(xxl) {
    .ca-table {
        &--order {
            thead {
                display: table-header-group;
            }

            td:nth-child(3),
            td:nth-child(5),
            td:nth-child(6),
            td:nth-child(7) {
                display: table-cell;
            }
        }

        &--offer-items {
            thead {
                display: table-header-group;
            }

            td:nth-child(3),
            td:nth-child(4),
            td:nth-child(5),
            tfoot td:first-child {
                display: table-cell;
            }
        }

        &--order-details__address-box {
            max-width: 140px;
        }

        &--shopping-list {
            thead th:nth-child(2) {
                display: table-cell;
            }

            tbody {
                td:nth-child(3) {
                    display: table-cell;
                    padding-right: 35px !important;
                }
            }

            tfoot {
                td:nth-child(2) {
                    display: table-cell;
                }
            }
        }

        &--users {
            .txt--truncate {
                max-width: 300px;
            }
            th,
            td,
            td:first-child,
            td:last-child {
                padding: $space * 3;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:698";