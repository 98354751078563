//Refactors: styles/v1/layout/_pdp.scss

.o-product-info {
    position: relative;
    width: 100%;
    margin-top: $space * 3;

    &__title {
        margin-bottom: $space * 2;
    }
}

;@import "sass-embedded-legacy-load-done:1281";