.product-gpsr-manufacturer {
    &__title {
        margin: $space * 4 0 $space * 2 0;
    }

    &__text {
        margin: $space 0;
    }
}

;@import "sass-embedded-legacy-load-done:1371";