.paypal-express-checkout-hint {
    color: $theme-color;
    text-align: center;
    cursor: default;
    margin: ($space * 2) 0;

    @include media(lg) {
        margin: 0;
    }

    &__icon {
        font-size: $font-size--medium;
    }

    &__label {
        font-weight: $font-weight--bold;
    }

    &__tooltip {
        min-width: 275px;
    }

    &--address {
        color: $grey--dark;
        text-align: left;

        .paypal-express-checkout-hint__label {
            margin-left: $space;
            font-weight: $font-weight--base;
        }
    }
}

.haulier-phone-hint {
    position: relative;
    color: $theme-color;
    margin-left: $space;
    text-align: center;

    &__icon {
        font-size: $font-size--medium;
    }

    &__tooltip {
        min-width: 275px;
        left: -240px;
        font-weight: $font-weight--base;

        @include media(xl) {
            left: -15px;
        }
    }

    .tooltip::before {
        left: calc(91% - 1px);

        @include media(xl) {
            left: calc(8% - 1px);
        }
    }

    .tooltip::after {
        left: calc(91% - 2px);

        @include media(xl) {
            left: calc(8% - 2px);
        }
    }
}

;@import "sass-embedded-legacy-load-done:308";