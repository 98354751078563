@include media(md) {
    .o-sponsored-label-modal {
        &__container {
            border-radius: 4px;
            max-height: calc(100% - 30px);
            max-width: 520px;
            overflow-y: auto;
            height: unset;
        }

        &__header {
            margin-top: -$space;
        }

        &__button {
            display: none;
        }

        &__title {
            margin-right: -$space * 2;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1163";