@include media(xxl) {
    .o-featured-product {
        flex-wrap: nowrap;

        &__image {
            &--large {
                width: 761px;
                height: 534px;
            }

            &--small {
                width: 316px;
                height: 421px;
            }
        }

        &__content {
            &--large {
                height: 534px;
                padding: ($space * 6) ($space * 11);
            }

            &--small {
                height: 421px;
                padding: ($space * 4) ($space * 6) ($space * 4) ($space * 8);
            }
        }

        &__header {
            &--large {
                margin-bottom: $space * 8;
            }

            &--small {
                margin-bottom: $space * 4;
            }
        }
        &__list--item {
            .o-featured-product__list--item--text {
                line-height: 28px;
            }
        }
    }
}

;@import "sass-embedded-legacy-load-done:1353";