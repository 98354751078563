//Refactors: styles/v2/components/_quantity-widget.scss

/* ------------------------------------ *\
    QUANTITY FIELD MD (+/-)
\* ------------------------------------ */
@include media(md) {
    .m-quantity-field {
        width: 140px;
    }
}

;@import "sass-embedded-legacy-load-done:1033";