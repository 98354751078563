@include media(xxl) {
    .ca-content--plus {
        &__points-overview {
            .panel__body {
                display: flex;
                justify-content: space-between;
            }
        }

        &__expiration-date {
            margin-top: $space * 3;
        }

        &__total {
            align-items: center;
        }
    }
}

;@import "sass-embedded-legacy-load-done:747";