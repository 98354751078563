@include media(xl) {
    .o-usp-banner {
        width: 954px;

        &__item {
            padding-left: $space * 7;
        }
    }
}

;@import "sass-embedded-legacy-load-done:1323";