.a-icon--medium {
    font-size: $font-size--medium;
}

.a-icon--medium-plus {
    font-size: $font-size--medium-plus;
}

.a-icon--large {
    font-size: $font-size--large;
}
;@import "sass-embedded-legacy-load-done:941";